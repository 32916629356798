import { DeleteOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  Form,
  Input,
  notification,
  PageHeader,
  Popconfirm,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tag,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { ColumnsType } from "antd/lib/table";
import moment from "moment/moment";
import React, { FC, Fragment, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Pagination } from "../../components/pagination";
import { isPlatform } from "../../utils/isPlatform";
import { request } from "../../utils/request";
import { ICity } from "../pages-manage";

export const Promotions: FC = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    data: [],
    pagination: {
      count: 0,
      limit: 0,
      offset: 0,
    },
  });
  const [creation_form] = useForm();
  const [filter_form] = useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState<any>({});
  const page = searchParams.has("page") ? Number(searchParams.get("page")) : 1;
  const [cities, setCities] = useState<ICity[]>([]);

  const getData = async () => {
    setLoading(true);
    const response = await request.post("/promotion/get", { ...filter, offset: 50 * (page - 1) });
    setData(response.data);
    setLoading(false);
  };

  const add = async (data: any) => {
    try {
      await request.post("/promotion/create", {
        profileId: Number(data.profileId),
        message: data.message,
        enabled: Boolean(data.enabled),
      });

      await getData();

      notification.success({
        message: "Успешно сохранено",
        description: "Давайте радоваться",
      });

      creation_form.resetFields();
    } catch (err) {
      console.error(err);
    }
  };

  const edit = async ({ id, message, enabled }: { id: number; message?: string; enabled?: boolean }) => {
    await request.post("/promotion/update", {
      id,
      message,
      enabled,
    });

    await getData();

    notification.success({
      message: "Успешно сохранено",
      description: "Давайте радоваться",
    });
  };

  const remove = async (id: number) => {
    await request.post("/promotion/delete", { id });
    await getData();
  };

  useEffect(() => {
    getData();
  }, [page, filter]);

  const getCities = async (id?: number) => {
    const response = await request.get("/geo/cities", {
      params: {
        countryId: id ?? 1,
      },
    });
    setCities(response.data);
  };

  useEffect(() => {
    getCities();
  }, []);

  const onValuesChange = async () => {
    setSearchParams({ page: "1" });
    const values = filter_form.getFieldsValue();
    setFilter(values);
  };

  const columns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Ид профиля",
      dataIndex: "profileId",
      key: "id",
      render: (_, { profile }) => profile.id,
    },
    {
      title: "Город",
      dataIndex: "city",
      key: "id",
      render: (_, { profile }) => profile.city.name,
    },
    {
      title: "Онлайн",
      dataIndex: "online",
      key: "id",
      render: (_, { profile }) => (profile.online ? <Tag color="green">Да</Tag> : <Tag color="red">Нет</Tag>),
    },
    {
      title: "Профиль",
      dataIndex: "profileId",
      key: "profileId",
      render: (_, { profile }) => {
        return (
          <a href={`/profile/${profile.id}`} rel="noreferrer">
            <Space>
              <Avatar
                src={
                  profile?.avatar
                    ? `${isPlatform("https://api.amorus.ru", "https://api.lovers.su")}${profile.avatar.image}`
                    : "https://amorus.ru/assets/images/stub_male.png"
                }
              />
              <span>{profile.name}</span>
            </Space>
          </a>
        );
      },
    },
    {
      width: 600,
      title: "Текст",
      dataIndex: "message",
      key: "message",
      render: (_, { message, id }) => (
        <Fragment>
          <Form
            onFinish={edit}
            initialValues={{
              id,
              message,
            }}
          >
            <Form.Item hidden name="id" rules={[{ required: true }]}>
              <Input hidden />
            </Form.Item>
            <Form.Item style={{ marginBottom: 12 }} name="message" rules={[{ required: true }]}>
              <Input.TextArea autoSize />
            </Form.Item>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form>
        </Fragment>
      ),
    },
    {
      title: "Дата создания",
      dataIndex: "createdAt",
      key: "id",
      render: (_, data) => moment(data.createdAt).format("DD.MM.YYYY HH:mm"),
    },
    {
      title: "Включено",
      dataIndex: "published",
      key: "published",
      render: (_, { id, enabled }) => (
        <Fragment>
          {enabled ? (
            <Button onClick={() => edit({ id, enabled: false })} size="small" type="primary">
              Да
            </Button>
          ) : (
            <Button onClick={() => edit({ id, enabled: true })} size="small" danger={true}>
              Нет
            </Button>
          )}
        </Fragment>
      ),
    },
    {
      title: "Управление",
      key: "action",
      render: (_, { id }) => (
        <Space>
          <Popconfirm placement="leftTop" title="Удалить?" onConfirm={() => remove(id)} okText="Да" cancelText="Нет">
            <Button size="small" danger={true} icon={<DeleteOutlined />}>
              Удалить
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <PageHeader ghost={false} title="Продвижение" />
      <Card style={{ marginBottom: 30 }} title="Добавить элемент">
        <Form form={creation_form} layout="inline" onFinish={add} size="middle">
          <Form.Item rules={[{ required: true, message: "Поле не должно быть пустым" }]} required name="profileId">
            <Input type="number" placeholder="ИД профиля" />
          </Form.Item>
          <Form.Item rules={[{ required: true, message: "Поле не должно быть пустым" }]} name="message">
            <Input placeholder="Сообщение" style={{ width: 600 }} />
          </Form.Item>
          <Form.Item label="Включено" name="enabled">
            <Switch />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Добавить
          </Button>
        </Form>
      </Card>

      <Form form={filter_form} onValuesChange={onValuesChange}>
        <Row gutter={16}>
          <Col span={4}>
            <Form.Item name="query">
              <Input allowClear placeholder="Поиск" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="country">
              <Select
                disabled={!!filter?.city}
                placeholder="Страна"
                allowClear
                showSearch
                onChange={async (value) => {
                  filter_form.setFieldValue("city", undefined);
                  await getCities(value);
                }}
                options={[
                  {
                    label: "Россия",
                    value: 1,
                  },
                  {
                    label: "РБ",
                    value: 2,
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="city">
              <Select
                placeholder="Город"
                showSearch
                allowClear
                filterOption={(text, value) => {
                  return String(value?.label || "")
                    .toLowerCase()
                    .includes(text.toLowerCase());
                }}
                options={cities.map((el) => ({ value: el.id, label: el.name }))}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Table
        loading={loading}
        rowKey="id"
        pagination={false}
        columns={columns}
        dataSource={data.data}
        scroll={{ x: 300 }}
      />
      <Pagination
        count={data.pagination.count}
        limit={data.pagination.limit}
        page={searchParams.has("page") ? Number(searchParams.get("page")) : 1}
      />
      <style>{`table td { vertical-align: top; }`}</style>
    </div>
  );
};
