import "./index.scss";

import { Button, Card, Form, Input, notification } from "antd";
import cookie from "cookiejs";
import { FC, useState } from "react";

import { request } from "../../utils/request";

const only_client_admin = ["admin@amorus.ru"];

export const Login: FC = () => {
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: any) => {
    setLoading(true);

    if (only_client_admin.includes(values.email)) {
      setLoading(false);
      notification.error({ message: "Аккаунт предназначен только для клиента" });
      return;
    }

    try {
      const { data } = await request.post("auth/login", {
        email: values.email,
        password: values.password,
      });

      if (data.token) {
        cookie.set("cp_token", data.token);
        window.location.reload();
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  // const [typeAdmin, setTypeAdmin] = useChangeType(); //lovers

  return (
    <div className="login">
      <Card>
        {/* <Switch
          checked={typeAdmin !== "lovers"}
          onChange={(e) => setTypeAdmin(e ? "amorus" : "lovers")}
          checkedChildren="amorus"
          unCheckedChildren="lovers"
          style={{ marginLeft: "auto" }}
        /> */}
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item label="E-mail" name="email" rules={[{ required: true, type: "email", message: "Введите E-mail" }]}>
            <Input disabled={loading} />
          </Form.Item>

          <Form.Item label="Пароль" name="password" rules={[{ required: true, message: "Введите пароль" }]}>
            <Input.Password disabled={loading} />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button loading={loading} type="primary" htmlType="submit">
              Войти
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};
