import { LinkOutlined } from "@ant-design/icons";
import { Space, Spin, Tag } from "antd";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import { NavLink } from "react-router-dom";

import { request } from "../../utils/request";

interface IsUniqueProps {
  profileId: number;
}

interface Device {
  id: number;
  profile_id: number;
  uuid: string;
  email: string;
  ip: string;
  dangerous: boolean;
}

interface Response {
  unique: boolean;
  current: Device[];
  devices: Device[];
}

export const IsUnique: FC<IsUniqueProps> = ({ profileId }) => {
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState<Response | null>(null);

  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  const checkIsUnique = async () => {
    try {
      const { data } = await request.post("/users/profile-is-unique", { profile_id: profileId });
      setResult(data);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const isDangerous = useMemo(() => {
    if (result) {
      const all = [...result.current, ...result.devices];
      return all.some(({ dangerous }) => dangerous);
    }

    return false;
  }, [result]);

  useEffect(() => {
    if (inView) {
      checkIsUnique();
    }
  }, [inView]);

  return (
    <div ref={ref}>
      {loading ? (
        <Spin size="small" />
      ) : result ? (
        <NavLink
          to={`/devices?ids=${[...result.current, ...result.devices].map(({ id }) => id).join(",")}`}
          rel="noreferrer"
        >
          <Space>
            <Tag icon={<LinkOutlined />} style={{ cursor: "pointer" }} color={result.unique ? "green" : "red"}>
              {result.unique ? "Да" : "Нет"}
            </Tag>
            {isDangerous && (
              <Tag style={{ cursor: "pointer" }} color="red">
                Подозрительный
              </Tag>
            )}
          </Space>
        </NavLink>
      ) : null}
    </div>
  );
};
