import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export type TEvents = "amorus" | "lovers";

export function useChangeType(): [TEvents, (newHeadCells: TEvents) => void] {
  const location = useLocation();
  const history = useNavigate();

  const [state, setState] = React.useState<TEvents>(
    window.location.pathname.startsWith("/lovers") ? "lovers" : "amorus",
  );

  React.useEffect(() => {
    if (window.location.pathname.startsWith("/lovers")) {
      setState("lovers");
    } else {
      setState("amorus");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const eventsToInstall = (newHeadCells: TEvents): void => {
    if (newHeadCells === "lovers" && state !== "lovers") {
      setState("lovers");
      history(`/lovers${location.pathname}`);
      window.location.reload();
    } else if (newHeadCells === "amorus" && state !== "amorus") {
      setState("amorus");
      window.location.href = `${window.location.origin}${location.pathname}`;
      // window.location.reload();
    }
  };
  return [state, eventsToInstall];
}
