import { Pagination as PaginationComponent } from "antd";
import React, { FC } from "react";
import { useSearchParams } from "react-router-dom";

interface IPagination {
  count: number;
  page: number;
  limit: number;
}

export const Pagination: FC<IPagination> = ({ count, page, limit }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const onChange = (page: number): void => {
    searchParams.set("page", String(page));
    setSearchParams(searchParams);
    window.scroll({ top: 0, behavior: "auto" });
  };

  if (count < limit) {
    return null;
  }

  return (
    <div style={{ paddingTop: 30 }}>
      <PaginationComponent
        pageSize={limit}
        showSizeChanger={false}
        total={count}
        current={page ?? 1}
        onChange={onChange}
      />
    </div>
  );
};
