export const domainNames: Record<string, string> = {
  amorus: "amorus",
  lovelaz: "lovelaz",
  soderzhanka: "soderzhanka",
};

export const domains: Record<string, string> = {
  amorus: "amorus.ru",
  lovelaz: "lovelaz.ru",
  soderzhanka: "soderzhanka.online",
};
