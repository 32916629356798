import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, PageHeader, Space, Table, Tag, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment/moment";
import React, { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Pagination } from "../../components/pagination";
import { IUser } from "../../interfaces/user";
import { request } from "../../utils/request";

export const Devices: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [ids, setIds] = useState(searchParams.has("ids") ? searchParams.get("ids")?.split(",") : undefined);

  const page = searchParams.has("page") ? Number(searchParams.get("page")) : 1;

  const [data, setData] = useState({
    data: [],
    pagination: {
      count: 0,
      limit: 0,
      offset: 0,
    },
  });

  const getData = async () => {
    const response = await request.post("/users/devices", {
      offset: 50 * (page - 1),
      query: String(query),
      ids,
    });
    setData(response.data);
    setLoading(false);
  };

  const search = (query: string) => {
    setQuery(query);
    setSearchParams({ page: "1" });
  };

  const clearIds = () => {
    setIds(undefined);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, query, ids]);

  const columns: ColumnsType<IUser> = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Профиль",
      dataIndex: "profile_id",
      render: (id) => {
        return (
          <Space>
            <a target="_blank" href={`https://amorus.ru/profile/${id}`} rel="noreferrer">
              {id}
            </a>
            <Button onClick={() => search(id)} size="small" icon={<SearchOutlined />}></Button>
          </Space>
        );
      },
    },
    {
      title: "Подозрительный",
      dataIndex: "dangerous",
      render: (dangerous) => {
        return <Tag color={dangerous ? "red" : "green"}>{dangerous ? "Да" : "Нет"}</Tag>;
      },
    },
    {
      title: "IP",
      dataIndex: "ip",
      render: (ip) => {
        return (
          <Space>
            <Typography.Text>{ip}</Typography.Text>
            <Button onClick={() => search(ip)} size="small" icon={<SearchOutlined />}></Button>
          </Space>
        );
      },
    },
    {
      title: "UUID",
      dataIndex: "uuid",
      render: (uuid) => {
        return (
          <Space>
            <Typography.Text>{uuid}</Typography.Text>
            <Button onClick={() => search(uuid)} size="small" icon={<SearchOutlined />}></Button>
          </Space>
        );
      },
    },
    {
      title: "Почта",
      dataIndex: "email",
      render: (email) => {
        return (
          <Space>
            <Typography.Text>{email}</Typography.Text>
            <Button onClick={() => search(email)} size="small" icon={<SearchOutlined />}></Button>
          </Space>
        );
      },
    },
    {
      title: "UA",
      dataIndex: "ua",
      width: 300,
      render: (ua) => {
        return (
          <Space>
            <Typography.Text>{ua}</Typography.Text>
            <Button onClick={() => search(ua)} size="small" icon={<SearchOutlined />}></Button>
          </Space>
        );
      },
    },
    {
      title: "Дата",
      dataIndex: "createdAt",
      render(date) {
        return moment(date).format("DD.MM.YYYY HH:mm");
      },
    },
  ];

  return (
    <div>
      <PageHeader ghost={false} title={`Устройства: ${data.pagination.count}`} />
      <Input.Search
        allowClear
        size="large"
        style={{ width: "100%", marginBottom: 16 }}
        placeholder="Поиск"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onSearch={search}
      />
      {ids ? (
        <Space size={1} style={{ marginBottom: 16 }}>
          {ids.map((id, index) => (
            <Tag key={index}>{id}</Tag>
          ))}
          <Button size="small" onClick={clearIds} type="link">
            Очистить
          </Button>
        </Space>
      ) : null}
      <Table
        loading={loading}
        rowKey="id"
        scroll={{ x: 300 }}
        pagination={false}
        columns={columns}
        dataSource={data.data}
      />
      <Pagination
        count={data.pagination.count}
        limit={data.pagination.limit}
        page={searchParams.has("page") ? Number(searchParams.get("page")) : 1}
      />
    </div>
  );
};
