import { IProfile } from "../interfaces/profile";
import { getFullFileUrl } from "./getFullFileUrl";

class ProfileClass {
  public getProfileAvatarUrl(profile?: IProfile): string {
    return profile?.avatar?.image
      ? getFullFileUrl(profile.avatar.image)
      : `https://amorus.ru/assets/images/stub_${profile?.sex || "male"}.png`;
  }
}

export const Profile = new ProfileClass();
