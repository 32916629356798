import { DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, PageHeader, Popconfirm, Row, Select, Space, Table, Tag, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Pagination } from "../../components/pagination";
import { domainNames, domains } from "../../contastants/domains";
import { landings } from "../../contastants/landings";
import { request } from "../../utils/request";
import { ICity } from "../pages-manage";

export const Pages: FC = () => {
  const [form] = Form.useForm();
  const [pages, setPages] = useState<any>({
    data: [],
    pagination: {
      count: 0,
      limit: 0,
      offset: 0,
    },
  });
  const [loading, setLoading] = useState(true);
  const [cities, setCities] = useState<ICity[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.has("page") ? Number(searchParams.get("page")) : 1;
  const [filter, setFilter] = useState({});

  const getCities = async (id?: number) => {
    const response = await request.get("/geo/cities", {
      params: {
        countryId: id ?? 1,
      },
    });
    setCities(response.data);
  };

  const getPages = async () => {
    const response = await request.post("/page/all", { ...filter, offset: 50 * (page - 1) });
    setPages(response.data);
    setLoading(false);
  };

  const deletePage = async (id: number) => {
    try {
      setLoading(true);
      await request.delete("/page", {
        data: { id },
      });
      await getPages();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPages();
  }, [page, filter]);

  useEffect(() => {
    getCities();
  }, []);

  const onValuesChange = async () => {
    setLoading(true);
    setSearchParams({ page: "1" });

    const values = form.getFieldsValue();

    if (values.query) {
      values.query = values.query.trim();

      try {
        values.query = new URL(values.query).pathname.replace(/\//g, "");
      } catch (err) {
        // Пупупу
      }
    }

    setFilter(values);
  };

  const columns: ColumnsType<any> = [
    {
      width: 50,
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Проект",
      dataIndex: "domain",
      key: "id",
      render: (domain) => {
        return <Tag color={domain === "amorus" ? "red" : "blue"}>{domain}</Tag>;
      },
    },
    {
      title: "Заголовок",
      dataIndex: "heading",
      key: "heading",
    },
    {
      title: "Алиас",
      dataIndex: "alias",
      key: "alias",
      render: (_, { alias, domain }) => {
        return (
          <a target="_blank" href={`https://${domains[domain]}/${alias}`} rel="noreferrer">
            {alias}
          </a>
        );
      },
    },
    {
      title: "Содержимое",
      dataIndex: "body",
      key: "body",
      render: (body: string | null) => {
        return body ? (
          <Typography.Text type="success">Заполнено</Typography.Text>
        ) : (
          <Typography.Text type="danger">Не заполнено</Typography.Text>
        );
      },
    },
    {
      title: "Город",
      render: (page) => {
        return page.city ? (
          <Typography.Text type="success">{page.city.name}</Typography.Text>
        ) : (
          <Typography.Text type="warning">Не заполнено</Typography.Text>
        );
      },
    },
    {
      title: "Шаблон",
      dataIndex: "template",
      render: (template) => {
        return landings[template] || template;
      },
    },
    {
      title: "Дата",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, { createdAt }) => {
        return moment(createdAt).format("DD.MM.YYYY HH:mm");
      },
    },
    {
      width: 200,
      title: "Управление",
      key: "action",
      render: (_, { id }) => (
        <Space>
          <Button href={`/pages/manage/${id}`} target="_blank" loading={loading} size="small">
            Редактировать
          </Button>
          <Popconfirm
            okType="danger"
            placement="leftTop"
            title="Точно отменить?"
            onConfirm={() => deletePage(id)}
            okText="Да"
            cancelText="Нет"
          >
            <Button loading={loading} size="small" danger={true} icon={<DeleteOutlined />}>
              Удалить
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <PageHeader
        ghost={false}
        title="Страницы"
        extra={[
          <Button href="/pages/manage" key="add" loading={loading} type="primary" size="middle" target="_blank">
            Добавить
          </Button>,
        ]}
      />
      <Form form={form} onValuesChange={onValuesChange}>
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item name="query">
              <Input allowClear placeholder="Поиск" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="template">
              <Select
                allowClear
                placeholder="Шаблон"
                options={Object.entries(landings).map(([value, label]) => ({ value, label }))}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="domain">
              <Select
                allowClear
                placeholder="Проект"
                options={Object.entries(domainNames).map(([value, label]) => ({ value, label }))}
              />
            </Form.Item>
          </Col>{" "}
          <Col span={6}>
            <Form.Item name="country">
              <Select
                placeholder="Страна"
                allowClear
                showSearch
                onChange={async (value) => {
                  form.setFieldValue("city", undefined);
                  await getCities(value);
                }}
                options={[
                  {
                    label: "Россия",
                    value: 1,
                  },
                  {
                    label: "РБ",
                    value: 2,
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="city">
              <Select
                placeholder="Город"
                showSearch
                allowClear
                filterOption={(text, value) => {
                  return String(value?.label || "")
                    .toLowerCase()
                    .includes(text.toLowerCase());
                }}
                options={cities.map((el) => ({ value: el.id, label: el.name }))}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
        loading={loading}
        rowKey="id"
        scroll={{ x: 300 }}
        pagination={false}
        columns={columns}
        dataSource={pages.data}
      />
      <Pagination
        count={pages.pagination.count}
        limit={pages.pagination.limit}
        page={searchParams.has("page") ? Number(searchParams.get("page")) : 1}
      />
    </div>
  );
};
