import { Alert, Avatar, Button, Image, PageHeader, Space } from "antd";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Spinner } from "../../components/spinner";
import { isPlatform } from "../../utils/isPlatform";
import { request } from "../../utils/request";
import { textFormate } from "../../utils/textFormate";

export const ChatMessages: FC = () => {
  const { chatId } = useParams();
  const [data, setData] = useState<any[]>([]);
  const [imageModalIsVisible, setImageModalIsVisible] = useState("");
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const getData = async () => {
    const response = await request.post("/administrator/chat/messages", {
      chatId,
    });

    setData(response.data);
    setLoading(false);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteChat = async (): Promise<void> => {
    await request.post("/administrator/chat/delete-chat", {
      id: chatId,
    });

    await getData();
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div>
      {
        <div style={{ display: "none" }}>
          <Image.PreviewGroup
            preview={{
              visible: !!imageModalIsVisible,
              onVisibleChange: (visible) => setImageModalIsVisible(visible ? "1" : ""),
            }}
          >
            <Image src={imageModalIsVisible} />
          </Image.PreviewGroup>
        </div>
      }
      <PageHeader
        ghost={false}
        title="Чат"
        extra={
          <Button onClick={deleteChat} danger>
            Удалить чат
          </Button>
        }
      />

      {data.length ? (
        <Space style={{ width: "100%" }} direction="vertical">
          {data.map((item) => (
            <Space
              style={{
                border: "1px solid #ccc",
                padding: 8,
                width: "100%",
                position: "relative",
              }}
              key={item.id}
            >
              <Space
                onClick={() => navigate(`/profile/${item.profile.id}`)}
                style={{ minWidth: 140, cursor: "pointer" }}
              >
                <Avatar
                  src={
                    item.profile?.avatar
                      ? `${isPlatform("https://api.amorus.ru", "https://api.lovers.su")}${item.profile.avatar.image}`
                      : "https://amorus.ru/assets/images/stub_male.png"
                  }
                />
                <span style={{ minWidth: 140 }}>{item.profile.name}</span>
              </Space>
              <span style={{ minWidth: 140, display: "inline-block" }}>
                {moment(item.createdAt).format("DD.MM.YYYY HH:mm")}
              </span>

              <div style={{ paddingRight: 140 }}>
                {textFormate(item.message, (url) => setImageModalIsVisible(url ?? ""))}
              </div>
              <Space style={{ position: "absolute", right: 16, top: 8 }}>
                <div onClick={() => navigate(`/profile/${item.chat.first_profile.id}`)} style={{ cursor: "pointer" }}>
                  <Avatar
                    src={
                      item.chat.first_profile?.avatar
                        ? `${isPlatform("https://api.amorus.ru", "https://api.lovers.su")}${item.chat.first_profile.avatar.image}`
                        : "https://amorus.ru/assets/images/stub_male.png"
                    }
                  />
                </div>
                <div onClick={() => navigate(`/profile/${item.chat.second_profile.id}`)} style={{ cursor: "pointer" }}>
                  <Avatar
                    src={
                      item.chat.second_profile?.avatar
                        ? `${isPlatform("https://api.amorus.ru", "https://api.lovers.su")}${item.chat.second_profile.avatar.image}`
                        : "https://amorus.ru/assets/images/stub_male.png"
                    }
                  />
                </div>
              </Space>
            </Space>
          ))}
        </Space>
      ) : (
        <Alert message="В этом чате нет сообщений :(" type="info" />
      )}
    </div>
  );
};
