export const textFormate = (text: string, cb?: (url?: string) => void): string | JSX.Element => {
  if (text.startsWith("<picture>{") && text.endsWith("}</picture>")) {
    const url = text.replace("<picture>{", "").replace("}</picture>", "");
    // eslint-disable-next-line jsx-a11y/img-redundant-alt
    return (
      <img
        onClick={cb ? () => cb(url) : undefined}
        src={url}
        style={{ width: 300, height: 300, objectFit: "contain" }}
        alt=""
      />
    );
  }
  return text;
};
