import { Alert, Avatar, Button, Image, Input, PageHeader, Space } from "antd";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Pagination } from "../../components/pagination";
import { Spinner } from "../../components/spinner";
import { isPlatform } from "../../utils/isPlatform";
import { request } from "../../utils/request";
import { textFormate } from "../../utils/textFormate";
import cls from "./style.module.scss";

export const Messages: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState("");

  const search = (query: string) => {
    setQuery(query);
    setSearchParams({ page: "1" });
  };

  const [data, setData] = useState<any>({
    data: [],
    pagination: {
      count: 0,
      limit: 0,
      offset: 0,
    },
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [imageModalIsVisible, setImageModalIsVisible] = useState("");
  const navigate = useNavigate();

  const page = searchParams.has("page") ? Number(searchParams.get("page")) : 1;

  const getData = async () => {
    const response = await request.post("/administrator/chat/last-messages", {
      offset: 500 * (page - 1),
      query,
    });
    setData(response.data);
    setLoading(false);
    window.scrollTo({ top: 0 });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, query]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div>
      <PageHeader ghost={false} title="Сообщения" />
      <Input.Search size="large" style={{ width: "100%", marginBottom: 16 }} placeholder="Поиск" onSearch={search} />

      {
        <div style={{ display: "none" }}>
          <Image.PreviewGroup
            preview={{
              visible: !!imageModalIsVisible,
              onVisibleChange: (visible) => setImageModalIsVisible(visible ? "1" : ""),
            }}
          >
            <Image src={imageModalIsVisible} />
          </Image.PreviewGroup>
        </div>
      }
      {data.data.length ? (
        <Space style={{ width: "100%" }} direction="vertical">
          {data.data.map((item: any) => (
            <Space
              style={{
                border: "1px solid #ccc",
                padding: 8,
                width: "100%",
              }}
              className={cls.card}
              key={item.id}
            >
              <Space
                onClick={() => navigate(`/profile/${item.profile.id}`)}
                style={{ minWidth: 140, cursor: "pointer" }}
              >
                <Avatar
                  src={
                    item.profile?.avatar
                      ? `${isPlatform("https://api.amorus.ru", "https://api.lovers.su")}${item.profile.avatar.image}`
                      : "https://amorus.ru/assets/images/stub_male.png"
                  }
                />
                <span style={{ minWidth: 140 }}>{item.profile.name}</span>
              </Space>
              <span style={{ minWidth: 140, display: "inline-block" }}>
                {moment(item.createdAt).format("DD.MM.YYYY HH:mm")}
              </span>
              <Button target="_blank" href={`/chats/messages/${item.chat_id}`} style={{ marginRight: 24 }}>
                Перейти в чат
              </Button>
              <span>{textFormate(item.message, (url) => setImageModalIsVisible(url ?? ""))}</span>
            </Space>
          ))}
          <Pagination
            count={data.pagination.count as number}
            limit={data.pagination.limit}
            page={searchParams.has("page") ? Number(searchParams.get("page")) : 1}
          />
        </Space>
      ) : (
        <Alert message="В этом чате нет сообщений :(" type="info" />
      )}
    </div>
  );
};
