export const isPlatform = (amorus: string | undefined, lovers: string | undefined, def?: string): string => {
  const typeAdmin = window.location.pathname.startsWith("/lovers") ? "lovers" : "amorus";

  if (amorus || lovers) {
    if (typeAdmin === "lovers" && lovers) {
      return lovers;
    }
    return amorus ?? def ?? "";
  }
  return def ?? "";
};
