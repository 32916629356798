import { OrderedListOutlined, SettingOutlined } from "@ant-design/icons";
import { Button, PageHeader, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { request } from "../../utils/request";

export const Ratings: FC = () => {
  const [pages, setPages] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const getPages = async () => {
    const response = await request.post("/ratings/sources/get");
    setPages(response.data);
    setLoading(false);
  };

  useEffect(() => {
    getPages();
  }, []);

  const columns: ColumnsType<any> = [
    {
      width: 50,
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Адрес",
      dataIndex: "host",
      key: "host",
    },
    {
      title: "Сайты",
      dataIndex: "sites_count",
      key: "id",
    },
    {
      width: 200,
      title: "Управление",
      key: "action",
      render: (_, { id, pages_count }) => (
        <Space>
          <Button
            icon={<OrderedListOutlined />}
            type="dashed"
            href={`/ratings-pages/${id}`}
            loading={loading}
            size="small"
            onClick={(e) => {
              e.preventDefault();
              navigate(`/ratings-pages/${id}`);
            }}
          >
            Статьи ({pages_count})
          </Button>
          <Button
            icon={<SettingOutlined />}
            type="primary"
            href={`/ratings/${id}`}
            loading={loading}
            size="small"
            onClick={(e) => {
              e.preventDefault();
              navigate(`/ratings/${id}`);
            }}
          >
            Управлять
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <PageHeader ghost={false} title="Источники" />
      <Table
        loading={loading}
        rowKey="id"
        scroll={{ x: 300 }}
        pagination={false}
        columns={columns}
        dataSource={pages}
      />
    </div>
  );
};
