import { CheckOutlined, DeleteOutlined, WarningOutlined } from "@ant-design/icons";
import { Button, Input, PageHeader, Popconfirm, Select, Space, Switch, Table, Tag, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Pagination } from "../../components/pagination";
import { request } from "../../utils/request";

export const Transactions: FC = () => {
  const [transactions, setTransactions] = useState<any>({ data: [], pagination: { count: 0, limit: 0, offset: 0 } });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.has("page") ? Number(searchParams.get("page")) : 1;
  const query = searchParams.has("query") ? searchParams.get("query") : undefined;
  const status = searchParams.has("status") ? searchParams.get("status") : undefined;
  const verification = searchParams.has("verification");

  const getTransactions = async () => {
    const response = await request.post("/payment/transaction/list", {
      offset: 50 * (page - 1),
      limit: 50,
      status,
      verification,
      query: query ? String(query) : query,
    });
    setTransactions(response.data);
    setLoading(false);
  };

  const confirmTransaction = async (billId: string) => {
    setLoading(true);
    try {
      await request.post("/payment/transaction/confirm", { billId });
      getTransactions();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const deleteTransaction = async (billId: string) => {
    setLoading(true);
    try {
      await request.post("/payment/transaction/delete", { billId });
      getTransactions();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const toVerification = async (billId: string, verification: boolean) => {
    setLoading(true);
    try {
      await request.post("/payment/transaction/verification", { billId, verification });
      getTransactions();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTransactions();
  }, [page, status, query, verification]);

  const columns: ColumnsType<any> = [
    {
      title: "Номер платежа",
      dataIndex: "billId",
      key: "billId",
    },
    {
      title: "Домен",
      dataIndex: "domain",
    },
    {
      title: "Платежная система",
      dataIndex: "payment_system",
      key: "payment_system",
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      render: (_, { status }) => {
        return (
          <Tag color={status === "paid" ? "green" : status === "waiting" ? "orange" : "red"}>
            {status === "paid" ? "Оплачен" : status === "waiting" ? "Ожидание платежа" : "Ошибка"}
          </Tag>
        );
      },
    },
    {
      title: "Стоимость",
      dataIndex: "amount",
      key: "amount",
      render: (_, { amount }) => {
        return <span>{amount} руб.</span>;
      },
    },
    {
      title: "Тип",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Значение",
      dataIndex: "value",
      key: "value",
    },
    {
      title: "Дата",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, { createdAt }) => {
        return moment(createdAt).format("DD.MM.YYYY HH:mm");
      },
    },
    {
      title: "Профиль",
      dataIndex: "profileId",
      key: "profileId",
      render: (_, { profileId }) => {
        return (
          <a onClick={() => navigate(`/profile/${profileId}`)} rel="noreferrer">
            {profileId}
          </a>
        );
      },
    },
    {
      title: "На проверке",
      dataIndex: "verification",
      render: (verification) => {
        return <Tag color={verification ? "red" : "default"}>{verification ? "Да" : "Нет"}</Tag>;
      },
    },
    {
      title: "Управление",
      key: "profileId",
      render: (_, { billId, status, verification }) => {
        return (
          <Space>
            <Button
              type="dashed"
              onClick={() => toVerification(billId, !verification)}
              size="small"
              icon={<WarningOutlined />}
            >
              {verification ? "Снять с проверки" : "На проверку"}
            </Button>
            {status === "waiting" && (
              <Popconfirm
                placement="leftTop"
                title="Уверен?"
                onConfirm={() => deleteTransaction(billId)}
                okText="Да"
                cancelText="Нет"
              >
                <Button onClick={() => confirmTransaction(billId)} type="primary" size="small" icon={<CheckOutlined />}>
                  Подтвердить
                </Button>
              </Popconfirm>
            )}
            <Popconfirm
              placement="leftTop"
              title="Уверен?"
              onConfirm={() => deleteTransaction(billId)}
              okText="Да"
              cancelText="Нет"
            >
              <Button danger icon={<DeleteOutlined />} type="primary" size="small">
                Удалить
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      <PageHeader
        ghost={false}
        title="Транзакции"
        extra={
          <Space size={16}>
            <Space>
              <Switch
                onChange={(verification) => {
                  if (verification) {
                    setSearchParams({ verification: String(verification) });
                  } else {
                    searchParams.delete("verification");
                    setSearchParams(searchParams);
                  }
                }}
              />
              <Typography.Text>На проверке</Typography.Text>
            </Space>
            <Input.Search
              defaultValue={searchParams.get("query") || ""}
              style={{ width: 300 }}
              allowClear={true}
              placeholder="Поиск"
              onChange={(e) => {
                const value = e.target.value;

                if (value) {
                  setSearchParams({ query: value });
                } else {
                  searchParams.delete("query");
                  setSearchParams(searchParams);
                }
              }}
            />
            <Select
              defaultValue={searchParams.get("status")}
              style={{ width: 200 }}
              placeholder="Выберите статус"
              options={[
                { label: "Оплачено", value: "paid" },
                { label: "Ожидание", value: "waiting" },
                { label: "Ошибка", value: "error" },
              ]}
              onChange={(status) => {
                if (status) {
                  setSearchParams({ status });
                } else {
                  searchParams.delete("status");
                  setSearchParams(searchParams);
                }
              }}
              allowClear
            />
          </Space>
        }
      />
      <Table
        loading={loading}
        rowKey="billId"
        pagination={false}
        scroll={{ x: 300 }}
        columns={columns}
        dataSource={transactions.data}
      />
      <Pagination
        count={transactions.pagination.count}
        limit={transactions.pagination.limit}
        page={searchParams.has("page") ? Number(searchParams.get("page")) : 1}
      />
    </div>
  );
};
