/* eslint-disable jsx-a11y/img-redundant-alt */
import { Alert, Avatar, Image, PageHeader, Space } from "antd";
import React, { FC, Fragment, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Pagination } from "../../components/pagination";
import { Spinner } from "../../components/spinner";
import { IPhoto, IProfile } from "../../interfaces/profile";
import { isPlatform } from "../../utils/isPlatform";
import { Profile } from "../../utils/Profile";
import { request } from "../../utils/request";
import cls from "./style.module.scss";

export const ListPhoto: FC = () => {
  const [searchParams] = useSearchParams();
  const [modalPhotos, setModalPhotos] = useState<IPhoto[]>([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [data, setData] = useState<any>({
    data: [],
    pagination: {
      count: 0,
      limit: 0,
      offset: 0,
    },
  });

  const [loading, setLoading] = useState<boolean>(true);
  const [imageModalIsVisible, setImageModalIsVisible] = useState(false);

  const page = searchParams.has("page") ? Number(searchParams.get("page")) : 1;

  const getData = async () => {
    const response = await request.post("/administrator-information/listPhoto", {
      offset: 50 * (page - 1),
      limit: 100,
    });
    setData(response.data);
    setLoading(false);
    window.scrollTo({ top: 0 });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  if (loading) {
    return <Spinner />;
  }

  // const analysis = async (image: string, adult: boolean): Promise<void> => {
  //   await axios
  //     .post("http://localhost:3300/image/train", {
  //       imageUrl: [image],
  //       bool: [adult],
  //     })
  //     .then(() => {
  //       notification.success({ message: "Всё хорошо", description: "Прокатило!" });
  //     })
  //     .catch(() => {
  //       notification.error({ message: "Ошибка", description: "Не прокатило..." });
  //     });
  // };

  return (
    <div>
      <PageHeader ghost={false} title="Приватные фотографии" />
      <div style={{ display: "none" }}>
        <Image.PreviewGroup
          preview={{
            destroyOnClose: true,
            current: currentSlide,
            visible: imageModalIsVisible,
            onVisibleChange: setImageModalIsVisible,
          }}
        >
          {modalPhotos.map(({ id, image }) => (
            <Image key={id} src={`${isPlatform("https://api.amorus.ru", "https://api.lovers.su")}${image}`} />
          ))}
        </Image.PreviewGroup>
      </div>
      {data.data.length ? (
        <Space style={{ width: "100%" }} direction="vertical">
          {data.data.map((profile: IProfile) => (
            <div key={profile.id}>
              <div className={cls.card}>
                <Space style={{ width: "100%", cursor: "pointer" }}>
                  <a target="_blank" href={`https://amorus.ru/profile/${profile.id}`} rel="noreferrer">
                    <Avatar src={Profile.getProfileAvatarUrl(profile)} />
                  </a>
                  <span style={{ minWidth: 140 }}>{profile.name}</span>
                </Space>
                {!!profile.private_photos && (
                  <div className={cls.photos}>
                    {profile.private_photos.map((image, index: number) => {
                      const urlImage = `${isPlatform("https://api.amorus.ru", "https://api.lovers.su")}${image.image}`;
                      return (
                        <Fragment key={index}>
                          <span className={cls.item}>
                            <div>
                              {/*<Button*/}
                              {/*  size="small"*/}
                              {/*  onClick={() =>*/}
                              {/*    axios*/}
                              {/*      .post("https://adult-ai.amorus.ru/image", { imageUrl: urlImage, bool: true })*/}
                              {/*      .then((e) => {*/}
                              {/*        Modal.success({*/}
                              {/*          title: e.data.isAdultContent ? "Обноружен контент 18+" : "Фото прошло проверку",*/}
                              {/*          content: (*/}
                              {/*            <div>*/}
                              {/*              <span>Расшифровка:</span>*/}
                              {/*              <div>*/}
                              {/*                {e.data.result.map((el: any) => (*/}
                              {/*                  <div key={el.className}>*/}
                              {/*                    <span>{el.className}</span>*/}
                              {/*                    <span>{`    ${Math.ceil(el.probability * 100)}%`}</span>*/}
                              {/*                  </div>*/}
                              {/*                ))}*/}
                              {/*              </div>*/}
                              {/*            </div>*/}
                              {/*          ),*/}
                              {/*        });*/}
                              {/*      })*/}
                              {/*  }*/}
                              {/*>*/}
                              {/*  Результат*/}
                              {/*</Button>*/}
                            </div>
                            <img
                              loading="lazy"
                              onClick={() => {
                                setModalPhotos(profile.private_photos || []);
                                setCurrentSlide(index);
                                setImageModalIsVisible(true);
                              }}
                              src={urlImage}
                              style={{
                                width: 150,
                                height: "auto",
                                aspectRatio: "5/7",
                                objectFit: "cover",
                              }}
                              alt="picture"
                            />
                          </span>
                        </Fragment>
                      );
                    })}
                  </div>
                )}
                {/* <span>{textFormate(item.message, (url) => setImageModalIsVisible(url ?? ''))}</span> */}
              </div>
            </div>
          ))}
        </Space>
      ) : (
        <Alert message="ну ху знает" type="info" />
      )}

      <Pagination
        count={data.pagination.count as number}
        limit={data.pagination.limit}
        page={searchParams.has("page") ? Number(searchParams.get("page")) : 1}
      />
    </div>
  );
};
